.login-logo {
  max-width: 160px;
}

.white {
  color: #f5f5f5 !important;
}

.ant-modal {
  padding-bottom: 0 !important;
}

.ant-input-number {
  width: 100% !important;
}

.text-neutral {
  color: #2f8ceb;
}

.pop-over-text-underline {
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
}

aside.ant-layout-sider {
    z-index: 1000;
}
