@import 'components/mixins.scss';

.base-modal {
  width: 550px !important;
  border-style: 'solid';
  border-color: 'white';
}

.model-title {
  margin-top: 1.5rem;
}

.content {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.icon {
  font-size: 4rem;
  margin-top: 1.5rem;
  color: $primary;
}
