@import 'components/mixins.scss';

.dropdown {
  padding-right: 15px;
  position: relative;
  cursor: pointer;
  @include transition-slow;

  &::after {
    opacity: 0.5;
    color: $gray-5;
    position: absolute;
    top: 45%;
    right: 0;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    @include transition-slow;
  }

  &:hover {
    color: $blue;

    &::after {
      color: $gray-6;
    }

    .icon {
      color: $primary;
    }
  }
}

.firstPortfolio {
  padding-right: 15px;
  position: relative;
  cursor: pointer;

  .firstButton {
    @include transition-slow;
    color: #d4a815;
    text-shadow: none !important;

    &:focus {
      color: #d19600;

      .firstIcon {
        color: #d19600;
      }
    }

    .firstIcon {
      @include transition-slow;
      margin-left: rem(8);
      margin-right: rem(8);
      position: relative;
      bottom: -2px;
      font-size: rem(17);
      color: #d4a815;
    }
  }

  &:hover {
    color: #d19600;

    .firstIcon {
      color: #d19600;
    }

    .firstButton {
      color: #d19600;
    }
  }

  &:focus {
    color: #d19600 !important;

    .firstButton {
      color: #d19600;
    }
  }
}

.firstButton {
  transition: background 0s ease !important;
  transition: color 0.2s ease !important;

  padding-left: 15px;
  background: $white !important;
  border-color: $white !important;

  &:hover {
    background: $white !important;
    border-color: $white !important;
  }

  &:focus {
    background: $white !important;
    border-color: $white !important;
  }
}

.icon {
  margin-right: rem(8);
  position: relative;
  bottom: -2px;
  font-size: rem(17);
  color: $gray-5;
  @include transition-slow;
}

// dark theme
[data-kit-theme='dark'] {
  .dropdown {
    color: $dark-gray-2;

    &:hover {
      color: $primary;
    }
  }

  .icon {
    color: $dark-gray-2;
  }

  .firstButton {
    padding-left: 15px;
    background: #0c0c1b !important;
    border-color: #0c0c1b !important;
    color: #f1cc53;

    &:hover {
      background: #0c0c1b !important;
      border-color: #0c0c1b !important;
    }

    &:focus {
      background: #0c0c1b !important;
      border-color: #0c0c1b !important;
      color: #d19600;
    }
  }
}
